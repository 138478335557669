.root{
  width: 100%;
  height: 100vh;
}

.desc {
  width: 100%;
  text-align: center;
  height: 20vh;
  line-height: 20vh;
  margin: 0;
  color: #333;
}

.btnContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80vh;
}
.btn{
  width: 320px;
  height: 160px;
  text-decoration: none;
}
