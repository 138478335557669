.book {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 400px;
  background: #fff;
  transform-style: preserve-3d;
  transform: translate(-50%,-50%) perspective(2000px);
  box-shadow: inset 300px 0 50px rgba(0,0,0,.5), 0 20px 100px rgba(0,0,0,.5);
  transition: 1s;
}
.book:hover{
  box-shadow: inset 20px 0 50px rgba(0,0,0,.5), 0 10px 100px rgba(0,0,0,.5);
}

.book:before{
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #475b02;
  transform-origin: bottom;
  transform: skewX(-45deg);
}
.book:after{
  content: '';
  position: absolute;
  right: -5px;
  top:0;
  width: 5px;
  height: 100%;
  background: #7EA301;
  transform-origin: left;
  transform: skewY(-45deg);
}

.cover{
  position: relative;
  background-image: radial-gradient(#aaca27,#98b913 ,#799604 , #3f5001);
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 100px #2c3801;
  transform-origin: left;
  transition: 1s cubic-bezier(.15,1.7,.84,.58);
}
.cover::before{
  content: 'Wei Yan';
  position: absolute;
  top: 20%;
  text-align: center;
  width: 100%;
  font-size: xx-large;
  font-weight: bolder;
  color: #fff;
}

.cover::after{
  content: 'A book about me';
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
  font-size: large;
  font-weight: bolder;
  color: #fff;
}

.book:hover .cover::before {
  content: '';
}
.book:hover .cover::after {
  content: '';
}
.book:hover .cover{
  transform: rotateY(-135deg);
}

.book .content{
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 20px;
  z-index: -1;
}

.list {
  margin-top: 80px;
}
.list li {
  cursor: pointer;
  margin: 10px 0;
}
