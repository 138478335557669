.root {
  margin: 20px 40px;
}
.root h3{
  font-style: italic;
}
.title{
  margin: 0 30px;
}
.location{
  margin: 0 30px;
  font-style: italic;
}
