@import url('https://fonts.googleapis.com/css?family=Quicksand');
body{
  margin: 0;
  padding: 0;
  background: #708d00;
  font-family: 'Quicksand', sans-serif;
}
.App{
  width: 100%;
  height: 100vh;
  display: flex;
}
.menu{
  width: 20%;
  height: 100vh;
  background: rgba(0,0,0,.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.main{
  width: 80%;
  height: 100vh;
}

.item{
  text-decoration: none;
  font-size: xx-large;
  height: 30vh;
  width: 100%;
  line-height: 30vh;
  text-align: center;
  color: white;
}
