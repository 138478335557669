.root{
  background: #fff;
  width: 100%;
  height: 100vh;
  font-family: sans-serif;
  font-size: large;
  overflow-y: scroll;
}
.title {
  text-align: center;
  text-transform: uppercase;
}
.desc {
  margin: 20px 40px;
}