@import url(https://fonts.googleapis.com/css?family=Quicksand);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ResumeHeader_header__19ycx {
  font-weight: bolder;
  text-align: center;
}
.ResumeHeader_header__19ycx a {
  margin: 0 5px;
}
.Resume_root__SiyOP{
  background: #fff;
  width: 100%;
  height: 100vh;
  font-family: sans-serif;
  font-size: large;
  overflow-y: scroll;
}
.Resume_title__1wwDS {
  text-align: center;
  text-transform: uppercase;
}
.Resume_desc__wLyTg {
  margin: 20px 40px;
}
.ResumeSkills_root__4R2Vv {
  margin: 20px 40px;
}
.ResumeSkills_root__4R2Vv h3{
  font-style: italic;
}

.ResumeDemos_root__Jo9uI {
  margin: 20px 40px;
}
.ResumeDemos_root__Jo9uI h3{
  font-style: italic;
}
.ResumeDemos_desc__1Ibki{
  font-size: medium;
}
.ResumeDemos_demo__1HBOZ {
  margin: 20px 60px;
}
.ResumeDemos_demo__1HBOZ h4 {
  margin: 5px 0;
}
.ResumeDemos_demo__1HBOZ p {
  margin : 5px 0;
}
.ResumeHistory_root__NjLtC {
  margin: 20px 40px;
}
.ResumeHistory_root__NjLtC h3{
  font-style: italic;
}
.ResumeHistory_item__3kTJ1{
  margin: 10px;
}
.ResumeHistory_header__2zKXa {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.ResumeHistory_company__25E-K {
  font-style: italic;
  margin: 0;
}
.ResumeEdu_root__2UbBn {
  margin: 20px 40px;
}
.ResumeEdu_root__2UbBn h3{
  font-style: italic;
}
.ResumeEdu_title__3iTFb{
  margin: 0 30px;
}
.ResumeEdu_location__10Gc-{
  margin: 0 30px;
  font-style: italic;
}

.Home_root__3LwQS{
  width: 100%;
  height: 100vh;
}

.Home_desc__224nn {
  width: 100%;
  text-align: center;
  height: 20vh;
  line-height: 20vh;
  margin: 0;
  color: #333;
}

.Home_btnContainer__3qQ19 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80vh;
}
.Home_btn__iJrtB{
  width: 320px;
  height: 160px;
  text-decoration: none;
}

.Card_colorCardBox__107V4{
  position: relative;
  width: 100%;
  height: 100%;
  background: #708d00;
  padding: 140px 40px 40px;
  box-shadow: 0 15px 45px rgba(0,0,0,.2);
  text-decoration: none;
}

.Card_colorCardBox__107V4::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform .5s;
}

.Card_colorCardBox__107V4:hover:before{
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform .5s;
}
.Card_colorCardBox__107V4 h2{
  color: #fff;
  margin: 20px;
  position: absolute;
  top: 80px;
  font-size: 3em;
  font-weight: 800;
  z-index: 1;
  opacity: .2;
  transition: .5s;
}
.Card_colorCardBox__107V4:hover h2{
  opacity: 1;
  color: #fff;
  transform: translateY(-40px);
}

.Card_colorCardBox__107V4 p{
  position: relative;
  font-size: 1.6em;
  z-index: 2;
  color: #333;
  transition: .5s;
}
.Card_colorCardBox__107V4:hover p{
  color: #fff;
}
body{
  margin: 0;
  padding: 0;
  background: #708d00;
  font-family: 'Quicksand', sans-serif;
}
.App{
  width: 100%;
  height: 100vh;
  display: flex;
}
.menu{
  width: 20%;
  height: 100vh;
  background: rgba(0,0,0,.3);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.main{
  width: 80%;
  height: 100vh;
}

.item{
  text-decoration: none;
  font-size: xx-large;
  height: 30vh;
  width: 100%;
  line-height: 30vh;
  text-align: center;
  color: white;
}

.Profile_root__3I9Xk {
  width: 100%;
  height: 100vh;
  position: relative;
}
.Profile_about__35o-T {
  width: auto;
  margin: 0 40px;
  display: flex;
  flex-direction: column;
}

.Profile_about__35o-T a {
  margin-left: 10px;
}
.Book_book__19B4S {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 300px;
  height: 400px;
  background: #fff;
  transform-style: preserve-3d;
  transform: translate(-50%,-50%) perspective(2000px);
  box-shadow: inset 300px 0 50px rgba(0,0,0,.5), 0 20px 100px rgba(0,0,0,.5);
  transition: 1s;
}
.Book_book__19B4S:hover{
  box-shadow: inset 20px 0 50px rgba(0,0,0,.5), 0 10px 100px rgba(0,0,0,.5);
}

.Book_book__19B4S:before{
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  width: 100%;
  height: 5px;
  background: #475b02;
  transform-origin: bottom;
  transform: skewX(-45deg);
}
.Book_book__19B4S:after{
  content: '';
  position: absolute;
  right: -5px;
  top:0;
  width: 5px;
  height: 100%;
  background: #7EA301;
  transform-origin: left;
  transform: skewY(-45deg);
}

.Book_cover__Y10hF{
  position: relative;
  background-image: radial-gradient(#aaca27,#98b913 ,#799604 , #3f5001);
  width: 100%;
  height: 100%;
  box-shadow: inset 0 0 100px #2c3801;
  transform-origin: left;
  transition: 1s cubic-bezier(.15,1.7,.84,.58);
}
.Book_cover__Y10hF::before{
  content: 'Wei Yan';
  position: absolute;
  top: 20%;
  text-align: center;
  width: 100%;
  font-size: xx-large;
  font-weight: bolder;
  color: #fff;
}

.Book_cover__Y10hF::after{
  content: 'A book about me';
  position: absolute;
  top: 40%;
  text-align: center;
  width: 100%;
  font-size: large;
  font-weight: bolder;
  color: #fff;
}

.Book_book__19B4S:hover .Book_cover__Y10hF::before {
  content: '';
}
.Book_book__19B4S:hover .Book_cover__Y10hF::after {
  content: '';
}
.Book_book__19B4S:hover .Book_cover__Y10hF{
  transform: rotateY(-135deg);
}

.Book_book__19B4S .Book_content__2wn5Y{
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  padding: 20px;
  z-index: -1;
}

.Book_list__2hI9R {
  margin-top: 80px;
}
.Book_list__2hI9R li {
  cursor: pointer;
  margin: 10px 0;
}

.Paper_root__1A4Nw {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  background: #948f4b;

  width: 960px;
  height: 1024px;
  z-index: 1000;
  box-shadow: 20px -40px 20px rgba(0,0,0,.6);
  transition: 1s;
}

.Paper_root__1A4Nw:before{
  content: '';
  position: absolute;
  top: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  background: #343504;
  transform-origin: bottom;
  transform: skewX(-45deg);
}
.Paper_root__1A4Nw:after{
  content: '';
  position: absolute;
  right: -2px;
  top:0;
  width: 2px;
  height: 100%;
  background: rgb(80, 79, 15);
  transform-origin: left;
  transform: skewY(-45deg);
}

.Paper_page__n7f9_{
  position: relative;
  background: #fff;
  width: 80%;
  height: 70vh;
  margin: 40px auto;
  padding-top: 40px;
}

.Paper_sticker__j1xPS {
  cursor: pointer;
  width: 180px;
  height: 30px;
  text-align: center;
  color: rgba(0,0,0,.6);
  line-height: 30px;
  font-weight: bolder;
  margin-left: -90px;
  border: 1px solid rgba(200, 200, 200, .8);
  background: rgba(254, 254, 254, .6);
  box-shadow: 0px 0 3px rgba(0, 0, 0, 0.1);
  transform: rotate(-5deg);

  position: absolute;
  left: 50%;
  top: -15px;
}
.Paper_show__3L6Sy {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.Paper_show__3L6Sy::-webkit-scrollbar{
  display: none;
}

.PdfResume_root__O0aRP {
    background: #fff;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
}
.PdfResume_webview__UFlYo {
    height: 100%;
}

