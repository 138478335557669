.colorCardBox{
  position: relative;
  width: 100%;
  height: 100%;
  background: #708d00;
  padding: 140px 40px 40px;
  box-shadow: 0 15px 45px rgba(0,0,0,.2);
  text-decoration: none;
}

.colorCardBox::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.3);
  transform: scaleY(0);
  transform-origin: top;
  transition: transform .5s;
}

.colorCardBox:hover:before{
  transform: scaleY(1);
  transform-origin: bottom;
  transition: transform .5s;
}
.colorCardBox h2{
  color: #fff;
  margin: 20px;
  position: absolute;
  top: 80px;
  font-size: 3em;
  font-weight: 800;
  z-index: 1;
  opacity: .2;
  transition: .5s;
}
.colorCardBox:hover h2{
  opacity: 1;
  color: #fff;
  transform: translateY(-40px);
}

.colorCardBox p{
  position: relative;
  font-size: 1.6em;
  z-index: 2;
  color: #333;
  transition: .5s;
}
.colorCardBox:hover p{
  color: #fff;
}